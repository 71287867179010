import { createApp } from "./app";

(<any>process).server = false;
(<any>process).client = true;

const { app, router } = createApp({
  state: (<any>window).__INITIAL_STATE__,
  isClient: true
});

router.onReady(() => {
  app.$mount("#app");
});
