import Vue from 'vue'


export enum VisibleMessageNameType {
  Success = 'success-settings-change',
  Failed = 'unsuccessful-settings-change',
}

const state = () => ({
  visibleMessageName: null as null | VisibleMessageNameType,
  messageText: '',
})

const mutations = {
  changeVisibleMessageName(state: any, newVisibleMessageName: VisibleMessageNameType) {
    state.visibleMessageName = newVisibleMessageName
  },
  changeMessageText(state: any, text: string) {
    state.messageText = text

  },
}

const actions = {
  /**
   * why? we have the mutation 'changeVisibleModalName' for doing that
   * @param context
   * @param param1
   */
  closeMessage(context: any) {
    context.commit('changeVisibleMessageName', null)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
