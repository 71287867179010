
























import Vue from 'vue';

export default Vue.extend({
  name: 'MultilayerBackground',
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    backgroundColor: {
      type: Object,
      default: () => {}
    },
    backgroundImage: {
      type: Object,
      default: () => {}
    },
    backgroundOverlay: {
      type: Object,
      default: () => {}
    },
    isBackgroundImageHidden: {
      type: Boolean,
      default: false
    },
    backgroundId: {
      type: String,
      default: ''
    },
    overlayId: {
      type: String,
      default: ''
    },
    imageId: {
      type: String,
      default: ''
    },
    imagePath: {
      type: Object,
      default: null
    }
  }
});
