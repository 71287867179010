






























import Vue from 'vue'
import { mapState } from 'vuex'
import ProfileDropdown from './ProfileDropdown.vue'
import Avatar from './avatar/Avatar.vue'
import Notifications from './Notifications.vue'
import { AccountDto } from '../../utils/api'

export default Vue.extend({
  props: {
    activeProfileDropdownItem: String,
    isProfileDropdownVisible: Boolean,
    toggleProfileDropdown: Function,
  },
  components: {
    Avatar,
    ProfileDropdown,
    Notifications,
  },
  computed: {
    ...mapState({
      user: (state: any) => <AccountDto>state.auth.user,
    }),
    avatarName(): string {
      return !!this.user.fullName ? this.user.fullName : 'itmeo'
    },
  },
})
