import { render, staticRenderFns } from "./NotificationsItem.vue?vue&type=template&id=40b38798&scoped=true&"
import script from "./NotificationsItem.vue?vue&type=script&lang=ts&"
export * from "./NotificationsItem.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationsItem.vue?vue&type=style&index=0&id=40b38798&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b38798",
  null
  
)

export default component.exports