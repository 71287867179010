import { PlanCategory, AccountDto } from "../utils/api";
import apiClient, { handleHttpErrors } from '../utils/apiClient'
import { Module, ActionTree } from 'vuex';
export interface AuthState {
  user: AccountDto | null;
  isUserLoggedIn: boolean;
}

const state = (): AuthState => ({
  isUserLoggedIn: false,
  user: null
});

const mutations = {
  changeIsUserLoggedIn(state: AuthState, isUserLoggedIn: boolean) {
    state.isUserLoggedIn = isUserLoggedIn;
  },
  readAllNotifications(state: AuthState) {
    if (state.user)
      state.user.plan.unreadNotifications = 0
  },
  changeUser(state: AuthState, user: AccountDto) {
    state.user = user;
  }
};
const actions: ActionTree<AuthState, any> = {
  async getUserInfo(context) {

    try {
      const { data } = await apiClient.accounts.accountsGet();
      context.commit('changeUser', data)
      context.commit('changeIsUserLoggedIn', true)
    } catch (error) {
      handleHttpErrors(context)
    }
  },
}
const getters = {
  isUserWithFreePlan(state: AuthState) {
    return state.user && state.user.plan.category === PlanCategory.Free;
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
} as Module<AuthState, any>;
