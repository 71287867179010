



























import Vue from "vue";
import { mapState } from "vuex";
import { getRedirectLoginUrl } from "../index.vue";

export default Vue.extend({
  computed: {
    ...mapState({
      isUserLoggedIn: (state: any) => state.auth.isUserLoggedIn
    }),
    srcPencilWhite() {
      return process.env.VUE_APP_BUCKET_NAME + "/Defaults/pencil-white.svg";
    },
    srcDesktop() {
      return process.env.VUE_APP_BUCKET_NAME + "/Defaults/desktop.svg";
    },
    srcTablet() {
      return process.env.VUE_APP_BUCKET_NAME + "/Defaults/tablet.svg";
    },
    srcMobile() {
      return process.env.VUE_APP_BUCKET_NAME + "/Defaults/modile.svg";
    }
  },
  props: {
    srcArrowLeft: String
  },
  methods: {
    async showScreen() {},
    async useTemplate(event: Event) {
      event.preventDefault();
      event.stopPropagation();

      if (!this.isUserLoggedIn) {
        location.href = getRedirectLoginUrl();
        return;
      }

      /*

      const { commit, dispatch } = this.$store

      const isCreatePage = this.projectId > 0
      if (isCreatePage) {
        dispatch('project/createPage', {
          projectId: this.projectId,
          templateId: this.templateId,
        })
        return
      }

      dispatch('project/createProject', { templateId: this.templateId })*/
    }
  }
});
