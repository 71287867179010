
























//TODO: we have several similar components, we need to choose one of this:
//1. src/components/SiteSettings/MadeWithAirtap.vue
//2. src/components/Publish/Elements/Branding.vue
import { computed, defineComponent } from '@vue/composition-api';

const BrandingThemeType = {
  White: 'White',
  Black: 'Black'
};

const BrandingPositionType = {
  BottomRight: 'BottomRight',
  BottomLeft: 'BottomLeft'
};

export default defineComponent({
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    openSettings: {
      type: Function,
      default: () => {}
    },
    isOuter: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const classesMadeWith = computed(() => {
      return {
        'made-with_black': props.value.theme === BrandingThemeType.Black,
        'made-with_white': props.value.theme === BrandingThemeType.White,
        'made-with_br': props.value.position === BrandingPositionType.BottomRight,
        'made-with_bl': props.value.position === BrandingPositionType.BottomLeft
      };
    });

    return {
      classesMadeWith
    };
  }
});
