var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"menu-internal"},[(_vm.isShowProduct)?_c('li',[_c('p',{staticClass:"menu-internal__list-link",class:{ isProductOpen: _vm.isProductOpen },attrs:{"data-text":"Product"},on:{"click":function($event){_vm.isProductOpen = true}}},[_vm._v("\n        Product\n        "),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","width":"16","height":"16"}},[_c('use',{attrs:{"xlink:href":"/img/components/header/dropdown_arrow.svg#dropdown_arrow","href":"/img/components/header/dropdown_arrow.svg#dropdown_arrow"}})])])]):_vm._e(),_vm._v(" "),_c('li',[_c('a',{staticClass:"menu-internal__list-link",class:{
          'menu-internal__list-link__active': _vm.activeItem === 'templates',
        },attrs:{"href":"/templates"}},[_vm._v("\n        Templates\n      ")])]),_vm._v(" "),(!_vm.isDefault)?_c('li',[_c('a',{staticClass:"menu-internal__list-link",class:{
          'menu-internal__list-link__active': _vm.activeItem === 'dashboard',
        },attrs:{"href":"/dashboard"}},[_vm._v("\n        Dashboard\n      ")])]):_vm._e(),_vm._v(" "),(_vm.isDefault)?_c('li',[_c('a',{staticClass:"menu-internal__list-link",class:{
          'menu-internal__list-link__active': _vm.activeItem === 'pricing',
        },attrs:{"href":"/pricing"}},[_vm._v("\n        Pricing\n      ")])]):_vm._e(),_vm._v(" "),(false)?_c('li',[_c('a',{staticClass:"menu-internal__list-link menu-internal__list-link--new",class:{
          'menu-internal__list-link__active': _vm.activeItem === 'feed',
        },attrs:{"href":"/feed"}},[_vm._v("\n        Discover\n        "),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","width":"30","height":"12"}},[_c('use',{attrs:{"xlink:href":"/img/components/header/sprite.svg#icon-new_icon","href":"/img/components/header/sprite.svg#icon-new_icon"}})])])]):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.isProductOpen)?_c('header-product-dropdown',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.isProductOpen = false); }),expression:"() => (isProductOpen = false)"}]}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }