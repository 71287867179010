
























































































import Vue from 'vue'
import { mapState } from 'vuex'
import apiClient, { handleHttpErrors } from '../../utils/apiClient'
import NotificationsItem from './NotificationsItem.vue'
import { NotificationDto, PlanCategory } from '../../utils/api'
export default Vue.extend({
  name: 'Notifications',
  components: { NotificationsItem },
  data() {
    return {
      notifications: [] as NotificationDto[],
      isOpen: false,
    }
  },
  async mounted() {
    try {
      let filter = {
        PageSize: 8,
        PageNumber: 1,
      } as any
      const { data: notifications } =
        await apiClient.notifications.notificationsGet(filter)
      this.notifications = notifications.items
    } catch (error) {
      handleHttpErrors(this.$store)
    }
  },
  computed: {
    ...mapState({
      category: (state: any) => state.auth.user.plan.category,
      userisAdmin: (state: any) => state.auth.user.isAdmin,
      countUnread: (state: any) => state.auth.user.plan.unreadNotifications,
    }),
    isProPlan(): boolean {
      return this.category == PlanCategory.Pro || this.userisAdmin
    },
    readNotifications(): Array<Object> {
      return this.countUnread > 0
        ? this.notifications.filter((item) => item.isRead)
        : this.notifications
    },
    unreadNotifications(): Array<Object> {
      return this.notifications.filter((item) => !item.isRead)
    },
  },
})
