

































































//it's used in airtap-publish-ruler => all paths must be relative
import Vue from 'vue'
import Menu from './Menu.vue'
import UpgradeToProButton from './UpgradeToProButton.vue'
import LogInButton from './LogInButton.vue'
import UserInfo from './UserInfo.vue'
import ProfileDropdown from './ProfileDropdown.vue'
import Burger from './Burger.vue'
import MainInternalMenu from './MainInternalMenu.vue'
import Overlay from './Overlay.vue'

import { mapGetters, mapState } from 'vuex'
import { AccountDto, PlanCategory } from '../../utils/api'

export default Vue.extend({
  components: {
    Menu,
    UpgradeToProButton,
    LogInButton,
    UserInfo,
    ProfileDropdown,
    Burger,
    Overlay,
    MainInternalMenu,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    isInnerPage: { type: Boolean, default: false },
    isEmptyHeader: { type: Boolean, default: false },
  },
  data() {
    return {
      PlanCategory,
      isProfileDropdownVisible: false,
      isUsersSitesDropdownVisible: false,
      isUsersPagesDropdownVisible: false,
      projectId: this.$route.params.projectId || null,
    }
  },
  computed: {
    ...mapState({
      templateId: (state: any) => state.editor.templateId,
      projects: (state: any) => state.project.projects,
      projectPages: (state: any) => state.project.projectPages,
      isUserLoggedIn: (state: any) => state.auth.isUserLoggedIn,
      user: (state: any) => <AccountDto>state.auth.user,
    }),
    ...mapGetters({
      isUserWithFreePlan: 'auth/isUserWithFreePlan',
    }),
    routeName(): string | undefined | null {
      return this.$route.name
    },
    selectedProject(): any {
      return this.projects.find((project: any) => {
        return project.id == this.projectId
      })
    },
    selectedPage(): any {
      return this.projectPages.find((page: any) => {
        return page.id == this.$route.params.pageId
      })
    },
    rootUrl(): string {
      return this.isUserLoggedIn ? '/dashboard' : '/'
    },
    activeDropdownItem(): string {
      if (this.$route.name === 'dashboard') return 'dashboard'
      if (this.$route.name === 'feed') return 'feed'
      if (this.$route.name === 'settings-section' && this.$route.params.section === 'help') return 'help'

      if (this.$route.name === 'settings-section' && this.$route.params.section === 'plans') return 'plans'

      if (this.$route.name === 'settings-section') return 'settings'

      return ''
    },
    selectedProjectName(): string {
      return this.routeName === 'projects-projectId-pages' ? this.selectedProject.name : ''
    },
    upgradeButtonCondition(): boolean {
      if (!this.isUserWithFreePlan) return this.isUserWithFreePlan
      return !this.user.isAdmin
    },
    srcLogo() {
      return process.env.VUE_APP_BUCKET_NAME + '/Defaults/logo-uplaunch.svg'
    },
  },
  async created() {
    if (!(<any>process).client) return
    document.addEventListener('click', this.hideProfileDropdown)
    document.addEventListener('click', this.hideEditDropdown)
    document.addEventListener('keyup', this.hideEditDropdown)
    document.addEventListener('keyup', this.hideProfileDropdown)
    document.addEventListener('click', this.hideMobileDropdown)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideProfileDropdown)
    document.removeEventListener('keyup', this.hideProfileDropdown)
    document.addEventListener('click', this.hideMobileDropdown)
  },
  methods: {
    toggleEditDropdown(popup: string) {
      if (popup === 'pages') {
        this.isUsersSitesDropdownVisible = false
        this.isUsersPagesDropdownVisible = !this.isUsersPagesDropdownVisible
      } else {
        this.isUsersPagesDropdownVisible = false
        this.isUsersSitesDropdownVisible = !this.isUsersSitesDropdownVisible
      }
    },
    toggleProfileDropdown(event: any) {
      if (event) event.preventDefault()

      this.isProfileDropdownVisible = !this.isProfileDropdownVisible
    },
    hideEditDropdown(event: any) {
      if (event) {
        if (
          event.key === 'Escape' ||
          // These are exceptions: the blocks on what click should NOT close the profile dropdown
          (!event.target.closest('div.editor-menu__iconed') && !event.target.closest('.edit-dropdown')) ||
          // These are blocks on what click SHOULD close the profile dropdown
          event.target.classList.contains('edit-dropdown-item__preview-wrap') ||
          event.target.closest('.edit-dropdown-item__preview-wrap') ||
          event.target.classList.contains('edit-dropdown__close') ||
          event.target.closest('.edit-dropdown__close') ||
          event.target.closest('.edit-dropdown-item__btn').classList.contains('settings') ||
          event.target.classList.contains('settings')
        ) {
          this.isUsersSitesDropdownVisible = false
          this.isUsersPagesDropdownVisible = false
        }
      }
    },
    hideProfileDropdown(event: any) {
      if (event) {
        if (
          event.key === 'Escape' ||
          // These are exceptions: the blocks on what click should NOT close the profile dropdown
          (!event.target.closest('.header__user-info') &&
            !event.target.closest('.user-info__profile-dropdown') &&
            !event.target.closest('.header__profile-dropdown') &&
            !event.target.closest('.header__profile-dropdown-toggler')) ||
          // These are blocks on what click SHOULD close the profile dropdown
          event.target.classList.contains('profile-dropdown__menu-item') ||
          event.target.closest('.profile-dropdown__menu-item') ||
          event.target.classList.contains('profile-dropdown__social-menu-item') ||
          event.target.closest('.profile-dropdown__social-menu-item') ||
          event.target.closest('.profile-dropdown__action') ||
          event.target.closest('.user-info__notification')
        ) {
          this.isProfileDropdownVisible = false
        }
      }
    },
    hideMobileDropdown(event: any) {
      if (event) {
        if (
          event.target.classList.contains('menu-dropdown__list-link_active') ||
          event.target.closest('.menu-dropdown__list-link_active') ||
          event.target.closest('.header__menu-dropdown-toggler')
        ) {
          return
        }
      }
    },
    handleLogInLinkClick(event: Event) {
      if (event) event.preventDefault()
      this.$router.push({
        path: '/login',
        query: { redirectUrl: this.$route.fullPath },
      })
    },
  },
})
