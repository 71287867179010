import { Api } from "./api";
import { VisibleMessageNameType } from '../store/ui'

const isDevelopment = process.env.NODE_ENV === "development";

class ApiClientFactory {
  static createApiClient() {
    const config = {
      baseURL: isDevelopment ? "http://localhost:3001" : process.env.BASE_URL,
      withCredentials: true,
      paramsSerializer: (params: any) => {
        const parts = [] as any[];

        const encode = (val: string, key: string) => {
          return encodeURIComponent(val)
            .replace(/%3A/gi, ":")
            .replace(/%24/g, "$")
            .replace(/%2C/gi, ",")
            .replace(/%20/g, key == "Search" ? "%20" : "+")
            .replace(/%5B/gi, "[")
            .replace(/%5D/gi, "]");
        };

        const convertPart = (key: string, val: any) => {
          if (val instanceof Date) val = val.toISOString();
          else if (val instanceof Object) val = JSON.stringify(val);

          parts.push(encode(key, key) + "=" + encode(val, key));
        };

        Object.entries(params).forEach(([key, val]) => {
          if (val === null || typeof val === "undefined") return;

          if (Array.isArray(val))
            val.forEach((v, i) => convertPart(`${key}[${i}]`, v));
          else convertPart(key, val);
        });

        return parts.join("&");
      }
    };

    console.log('config.BASE_URL', config.baseURL)

    return new Api(config);
  }
}

export default ApiClientFactory.createApiClient();

export function handleHttpErrors({ commit }: { commit: Function }, $data?: any, reject?: () => void) {
  commit(
    'ui/changeVisibleMessageName',
    VisibleMessageNameType.Failed
  )
  commit(
    'ui/changeMessageText',
    "Something went wrong"
  )
  setTimeout(() => {
    commit("ui/changeMessageText", "");
  }, 3000);
  setTimeout(() => {
    if ($data && typeof $data.isProcessing !== "undefined") {
      $data.isProcessing = false
    }
    if ($data && typeof $data.isSuccessed !== "undefined") {
      $data.isSuccessed = false
    }
    if (reject) {
      reject();
    }
  }, 1000)
}

export function handleFormErrors(error: any, inputErrors: any, $data: any, $store: any) {
  let errorText = "Something went wrong"
  if (error.response.data) {
    var key: string
    let errors = error.response.data
    for (key in errors) {
      inputErrors[`${key}`] = errors[key]
      errorText = errors[key]
    }
  }
  $store.commit(
    'ui/changeVisibleMessageName',
    VisibleMessageNameType.Failed
  )
  $store.commit(
    'ui/changeMessageText',
    errorText
  )
  setTimeout(() => {
    $store.commit("ui/changeMessageText", "");
  }, 3000);
  setTimeout(() => {
    $data.isProcessing = false
  }, 1000)
}