import {
  PublishedCommunityProjectDto,
  PublishedProjectPageSettingsDto
} from "../utils/api";
import { Module, ActionTree } from "vuex";
export interface PageState {
  pageId: string;
  communityProject: PublishedCommunityProjectDto | null;
  settings: any;
  projectSettings: PublishedProjectPageSettingsDto | null;
  info: {
    title: string;
    description: string;
    keywords: string;
    isScreenshot: boolean;
  };
  socialTitle: string;
  isIncreasedProjectLimits: boolean;
}

const state = (): PageState => ({
  pageId: "",
  communityProject: null,
  settings: null,
  projectSettings: null,
  info: { title: "", description: "", keywords: "", isScreenshot: false },
  socialTitle: "",
  isIncreasedProjectLimits: false
});
const mutations = {
  SET_PAGE_ID(state: any, pageId: string) {
    state.pageId = pageId;
  },
  SET_PAGE_SETTINGS(state: any, settings: any) {
    state.settings = settings;
  },
  SET_SOCIALTITLE(state: any, socialTitle: any) {
    state.socialTitle = socialTitle;
  },
  SET_PROJECT_SETTINGS(state: any, projectSettings: any) {
    state.projectSettings = projectSettings;
  },
  SET_PAGE_INFO(state: any, info: any) {
    state.info = info;
  },

  SET_IS_INCREASED_PROJECT_LIMITS(
    state: any,
    isIncreasedProjectLimits: boolean
  ) {
    state.isIncreasedProjectLimits = isIncreasedProjectLimits;
  },
  SET_COMMUNITYPROJECT_INFO(state: any, communityProject: any) {
    state.communityProject = communityProject;
  },
  CHANGE_COMMUNITYPROJECT_CURRENTUSERAUTORIZED(
    state: any,
    isAuthorized: boolean
  ) {
    state.communityProject.currentUserAuthorized = isAuthorized;
  }
};
export default {
  namespaced: true,
  state,
  mutations
} as Module<PageState, any>;
