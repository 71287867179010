





















import useInjectStylesPublishVdr from '../../hooks/publish/injectStylesPublishVdr.hook';
import { computed, defineComponent } from '@vue/composition-api';
import { MetaInfo } from 'vue-meta';

import { formatLocalUrl, LinkType } from '../../constants/link';

export default defineComponent({
  name: 'IconElementPublish',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  metaInfo(): MetaInfo {
    return {
      style: [{ type: 'text/css', cssText: this.stylesToHead }],
      __dangerouslyDisableSanitizers: ['style']
    };
  },
  setup(props, { root }) {
    const stylesToHead = useInjectStylesPublishVdr(props.value);
    const overlay = props.value.styles.laptop['overlay'];

    const overlayStyle = computed(() => {
      if (!overlay) return null;
      return {
        borderRadius: props.value.styles.laptop['borderRadius'],
        backgroundColor: overlay
      };
    });

    const hrefValue = formatLocalUrl(root.$store.state, props.value.payload.link);

    return {
      LinkType,
      overlayStyle,
      stylesToHead,
      hrefValue,
      element: props.value
    };
  }
});
