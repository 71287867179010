






import { defineComponent } from '@vue/composition-api';
import { MetaInfo } from 'vue-meta';
import useInjectStylesPublishVdr from "../../hooks/publish/injectStylesPublishVdr.hook";

export default defineComponent({
  name: 'FormElementInputPublish',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  metaInfo(): MetaInfo {
    return {
      style: [{ type: 'text/css', cssText: this.stylesToHead }],
      __dangerouslyDisableSanitizers: ['style']
    };
  },
  setup(props, { root }) {
    const stylesToHead = useInjectStylesPublishVdr(props.value);

    return {
      stylesToHead,
      element: props.value,
      text: props.value.payload.text
    };
  }
});
