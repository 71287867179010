







































































import Vue from "vue";
import { mapState } from "vuex";
import { PublishedCommunityProjectDto } from "../../utils/api";
export default Vue.extend({
  computed: {
    ...mapState({
      communityProject: (state: any) =>
        <PublishedCommunityProjectDto>state.page.communityProject
    }),
    srcDefaultAvatar() {
      return process.env.VUE_APP_BUCKET_NAME + "/Defaults/avatar.jpg";
    },
    srcsetDefaultAvatar() {
      return process.env.VUE_APP_BUCKET_NAME + "/Defaults/avatar@2x.jpg 2x";
    }
  },
  props: {
    isDownloadStarted: Boolean,
    downloadProgress: Number
  }
});
