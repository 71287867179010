













import { defineComponent } from '@vue/composition-api';

import useInjectStylesPublishVdr from '../../hooks/publish/injectStylesPublishVdr.hook';
import useInjectCustomBrakePointForStyleEntity from '../../hooks/publish/injectCustomBrakePointForStyleEntity.hook';
import { customQueries } from '../../constants/breakpoints';
import { MetaInfo } from 'vue-meta';

import { LinkType, formatLocalUrl } from '../../constants/link';

export default defineComponent({
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  metaInfo(): MetaInfo {
    return {
      style: [{ type: 'text/css', cssText: this.stylesToHead }],
      __dangerouslyDisableSanitizers: ['style']
    };
  },
  setup(props, { root }) {
    let stylesToHead = useInjectStylesPublishVdr(props.value);
    stylesToHead += useInjectCustomBrakePointForStyleEntity('fontSize', customQueries, props.value);

    const hrefValue = formatLocalUrl(root.$store.state, props.value.payload.link);

    return {
      LinkType,
      stylesToHead,
      hrefValue,
      tag: hrefValue ? 'a' : props.value.payload.type
    };
  }
});
