





































































































//
import Vue from 'vue'
import { mapState } from 'vuex'

import UpgradeToProButton from './UpgradeToProButton.vue'
import Avatar from './avatar/Avatar.vue'

import apiClient, { handleHttpErrors } from '../../utils/apiClient'
import { AccountDto, PlanCategory } from '../../utils/api'

export default Vue.extend({
  components: {
    Avatar,
    UpgradeToProButton,
  },
  props: {
    activeDropdownItem: {
      type: String,
      default: '',
    },
    isVisible: Boolean,
  },
  data() {
    return {
      PlanCategory,
    }
  },
  computed: {
    ...mapState({
      user: (state: any) => <AccountDto>state.auth.user,
    }),
    avatarName(): string {
      return !!this.user.fullName ? this.user.fullName : 'itmeo'
    },
    menuTop(): Array<any> {
      return [
        {
          name: 'Dashboard',
          icon: 'dashboard',
          link: '/dashboard',
          isNewPage: false,
        },
        {
          name: 'View profile',
          icon: 'profile',
          link: `/${this.user.userName}`,
          isNewPage: true,
        },
        {
          name: 'Templates',
          icon: 'templates',
          link: '/templates',
          isNewPage: false,
        },
        /*         {
          name: 'Discover',
          icon: 'discover',
          link: '/feed',
          isNewPage: false,
        }, */
      ]
    },
    menuBottom(): Array<any> {
      return [
        {
          name: 'Edit Profile',
          icon: 'edit-profile',
          link: '/settings/profile',
        },
        {
          name: 'Settings',
          icon: 'settings_h',
          link: '/dashboard/settings',
        },
        {
          name: 'Plans',
          icon: 'star',
          link: '/dashboard/plans',
          isBold: true,
        },
        {
          name: 'Help',
          icon: 'help',
          link: '/dashboard/help',
        },
        {
          name: 'Log out',
          icon: 'logout',
          click: this.logout,
          isClick: true,
        },
      ]
    },
    srcFacebook() {
      return process.env.VUE_APP_BUCKET_NAME + '/Defaults/facebook.svg'
    },
    srcTwitter() {
      return process.env.VUE_APP_BUCKET_NAME + '/Defaults/x-logo.svg'
    },
    srcInstagram() {
      return process.env.VUE_APP_BUCKET_NAME + '/Defaults/instagram.svg'
    },
  },
  methods: {
    logout(event: Event) {
      if (event) event.preventDefault()

      apiClient.auth
        .authLogout()
        .then(() => {
          //this.$store.commit('auth/changeIsUserLoggedIn', false)
          // this.$store.commit('auth/changeUser', null)
          location.href = '/login'
        })
        .catch((error) => handleHttpErrors(this.$store))
    },
  },
})
