import Vue from "vue";
import Router from "vue-router";
import IndexPage from "../pages/index.vue";
import NotFoundPage from "../pages/404.vue";

Vue.use(Router);

const routes = [
  {
    path: "/projects/:projectId/pages/:pageId",
    component: IndexPage
  },
  {
    path: "/404",
    component: NotFoundPage
  },
  {
    path: "*",
    component: IndexPage
  }
];

export function createRouter(baseURL: any) {
  return new Router({
    mode: "history",
    base: baseURL,
    routes
  });
}
