<template>
  <div
    :class="{ 'product-dropdown__default': !isDefault }"
    class="product-dropdown"
  >
    <div class="product-dropdown__top">
      <div
        class="product-dropdown__col"
        v-for="(col, index) in dataList"
        :key="index"
      >
        <p class="product-dropdown__col-title">{{ col.title }}</p>
        <ul class="product-dropdown__col-list">
          <li
            class="product-dropdown__col-item"
            v-for="(item, colIndex) in col.list"
            :key="colIndex"
          >
            <router-link class="product-dropdown__col-link" :to="item.link">
              {{ item.text }}

              <svg
                width="12"
                height="7"
                viewBox="0 0 12 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                id="next-icon"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.6833 6.68452C7.92328 6.92488 8.31268 6.92519 8.55304 6.6852L11.7432 3.49999L8.55304 0.314779C8.31268 0.0747948 7.92328 0.0750995 7.6833 0.315459C7.44331 0.55582 7.44362 0.945216 7.68398 1.1852L9.38643 2.88499L0.872813 2.88499C0.533157 2.88499 0.257813 3.16034 0.257813 3.49999C0.257813 3.83965 0.533157 4.11499 0.872813 4.11499L9.38643 4.11499L7.68398 5.81478C7.44362 6.05476 7.44331 6.44416 7.6833 6.68452Z"
                  fill="#333333"
                />
              </svg>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!--  <div class="product-dropdown__bottom">-->
    <!--    <p class="product-dropdown__bottom-text">Explore Airtap Features</p>-->
    <!--    <svg-icon width="16" height="16" name="next-icon" class="product-dropdown__arrow" color="#3896EC" />-->
    <!--  </div>-->
  </div>
</template>

<script>
export default {
  name: 'HeaderProductDropdown',
  props: {
    isDefault: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  data() {
    return {
      dataList: [
        {
          title: 'PLATFORM',
          list: [
            {
              text: 'Website builder',
              link: '/website-builder',
            },
            {
              text: 'Marketing platform',
              link: '/marketing',
            },
            {
              text: 'Airtap Community',
              link: '/community',
            },
          ],
        },
        {
          title: 'SOLUTIONS',
          list: [
            {
              text: 'For creators',
              link: '/creators',
            },
            {
              text: 'For business',
              link: '/business',
            },
          ],
        },
        {
          title: 'USE CASES',
          list: [
            {
              text: 'Websites',
              link: '/cases/website',
            },
            {
              text: 'NFT galleries',
              link: '/cases/NFT-gallery',
            },
            {
              text: 'Digital downloads',
              link: '/cases/digital-download',
            },
            {
              text: 'Portfolio/CV',
              link: '/cases/portfolio',
            },
            {
              text: 'Blog',
              link: '/cases/blog',
            },
          ],
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.product-dropdown {
  background: $white;
  border-radius: $shape_border_radius;
  position: absolute;
  left: 20px;
  bottom: -12px;
  transform: translate(0px, 100%);
  overflow: hidden;
  box-shadow: $shadow_popup;
  &__default {
    transform: translate(111px, 100%) !important;
  }
  @include bp(767px) {
    right: 15px;
    left: unset;
    transform: translate(0, 100%);
  }

  &__top {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(3, 184px);
    gap: 30px;
    width: 100%;

    @include bp(767px) {
      gap: 15px;
      padding: 15px;
      grid-template-columns: repeat(3, 150px);
    }
  }

  &__col {
    position: relative;

    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background: $gray200;
      }
    }

    &-title {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #5e5e5e;
      opacity: 0.7;
      margin: 0 0 15px;
    }
    &-item {
      &:not(:last-child) {
        margin: 0 0 15px;
      }
    }
    &-link {
      font-size: 14px;
      line-height: 21px;
      color: $gray900;
      text-decoration: none;
      position: relative;
      overflow: hidden;
      display: inline-flex;
      transition: 0.3s;
      gap: 8px;
      align-items: center;

      svg {
        transition: 0.3s;
        transform: translate(-5px);
        opacity: 0;
      }

      &:hover {
        svg {
          transform: translate(0);
          opacity: 1;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 15px 30px;
    background: rgba(#adbbff, 0.2);
    cursor: pointer;
    transition: 0.3s;

    @include bp(767px) {
      padding: 7px 15px;
    }

    &-text {
      font-size: 13px;
      line-height: 20px;
      color: #3896ec;
      transition: 0.3s;
      font-weight: 600;
    }

    svg {
      transition: 0.3s;
    }

    &:hover {
      background: #173bef;
      .product-dropdown__bottom-text,
      svg {
        color: $white !important;
      }
    }
  }
}
</style>
