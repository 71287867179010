












































































import Vue from 'vue'
import HeaderProductDropdown from './HeaderProductDropdown.vue'

export default Vue.extend({
  components: {
    HeaderProductDropdown,
  },
  props: {
    activeLink: {
      type: String,
      default: '',
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isProductOpen: false,
    }
  },
  computed: {
    activeItem(): string {
      if (!this.activeLink) return ''
      const routePathParts = this.activeLink.split('/')
      if (routePathParts && routePathParts.length > 0) {
        return routePathParts[1]
      } else return ''
    },
    isShowProduct(): boolean {
      if (!this.activeLink) return false
      const routePathParts = this.activeLink.split('/')
      if (
        routePathParts &&
        routePathParts.length > 0 &&
        (routePathParts[1] == 'templates' || routePathParts[1] == 'feed')
      ) {
        return true
      } else return false
    },
  },
})
