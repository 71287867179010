


























import { computed, defineComponent } from '@vue/composition-api';
import useInjectSeparateBlocksStyles from '../../hooks/publish/injectSeparateBlocksStyles.hook';
import { MetaInfo } from 'vue-meta';

import { formatLocalUrl, LinkType } from '../../constants/link';

export default defineComponent({
  name: 'ImageElementPublish',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  metaInfo(): MetaInfo {
    return {
      style: [{ type: 'text/css', cssText: this.stylesToHead }],
      __dangerouslyDisableSanitizers: ['style']
    };
  },
  setup(props, { root }) {
    const dynamicBlockStyleCollection = [
      {
        idName: `#${props.value.id}`,
        stylesToSplit: ['display', 'borderRadius', 'opacity', 'objectFit', 'boxShadow', 'border']
      },
      {
        idName: `#${props.value.id}__overlay`,
        stylesToSplit: ['overlay', 'borderRadius', 'displayOverlay']
      }
    ];
    const stylesToHead = useInjectSeparateBlocksStyles(props.value, dynamicBlockStyleCollection);

    const hrefValue = formatLocalUrl(root.$store.state, props.value.payload.link);

    return {
      LinkType,
      stylesToHead,
      hrefValue,
      element: props.value
    };
  }
});
