import { Store } from "vuex";
import auth from './auth';
import ui from './ui';
import page from './page';

const createStore = (state: any) => {
  return new Store({
    strict: false,
    modules: {
      auth: Object.assign({}, auth, { state: () => state.auth }),
      page: Object.assign({}, page, { state: () => state.page }),
      ui: Object.assign({}, ui, { state: () => state.ui }),
    }
  });
};

export default createStore;