



























import useInjectSeparateBlocksStyles from '../../hooks/publish/injectSeparateBlocksStyles.hook';
import { computed, defineComponent } from '@vue/composition-api';
import { MetaInfo } from 'vue-meta';

import { formatLocalUrl, LinkType } from '../../constants/link';
import useInjectCustomBrakePointForStyleEntity from '../../hooks/publish/injectCustomBrakePointForStyleEntity.hook';
import { customQueries } from '../../constants/breakpoints';

export default defineComponent({
  name: 'ButtonElementPublish',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  metaInfo(): MetaInfo {
    return {
      style: [{ type: 'text/css', cssText: this.stylesToHead }],
      __dangerouslyDisableSanitizers: ['style']
    };
  },
  setup(props, { root }) {
    const dynamicBlockStyleCollection = [
      {
        idName: `#${props.value.id}`,
        stylesToSplit: [
          'textAlign',
          'textDecoration',
          'textTransform',
          'letterSpacing',
          'font',
          'color',
          'borderRadius',
          'border',
          'background',
          'boxShadow',
          'display',
          'justifyContent',
          'alignItems',
          'gridGap'
        ]
      },
      {
        idName: `#${props.value.id}-icon`,
        stylesToSplit: ['width']
      }
    ];
    let stylesToHead = useInjectSeparateBlocksStyles(props.value, dynamicBlockStyleCollection);
    stylesToHead += useInjectCustomBrakePointForStyleEntity('fontSize', customQueries, props.value);

    const iconSrc = computed(() => {
      if (!props.value.payload.image.fullPath) return '';
      return props.value.payload.image.fullPath;
    });
    const isIconHidden = computed(() => {
      if (props.value.payload.isIconHidden === undefined) return false;
      return props.value.payload.isIconHidden;
    });
    const isIconLeft = computed(() => {
      if (props.value.payload.iconPosition === undefined) return 'left';
      return props.value.payload.iconPosition === 'left';
    });

    const hrefValue = formatLocalUrl(root.$store.state, props.value.payload.link);

    return {
      LinkType,
      stylesToHead,
      hrefValue,
      element: props.value,
      iconSrc,
      isIconHidden,
      isIconLeft
    };
  }
});
