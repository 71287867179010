






















































































import Vue from 'vue'
import { mapState } from 'vuex'

import UpgradeToProButton from './UpgradeToProButton.vue'

import apiClient from '../../utils/apiClient'
import { AccountDto, PlanCategory } from '../../utils/api'

export default Vue.extend({
  components: {
    UpgradeToProButton,
  },
  props: {
    activeDropdownItem: {
      type: String,
      default: '',
    },
    isVisible: Boolean,
  },

  computed: {
    ...mapState({
      user: (state: any) => <AccountDto>state.auth.user,
    }),
    currentYear(): number {
      return new Date().getFullYear()
    },
    srcFacebook() {
      return process.env.VUE_APP_BUCKET_NAME + '/Defaults/facebook.svg'
    },
    srcTwitter() {
      return process.env.VUE_APP_BUCKET_NAME + '/Defaults/x-logo.svg'
    },
    srcInstagram() {
      return process.env.VUE_APP_BUCKET_NAME + '/Defaults/instagram.svg'
    },
  },
  methods: {
    singUp() {
      if (location.pathname.includes('login') || location.pathname.includes('sign-up')) location.href = `/sign-up`
      else location.href = `/sign-up?redirectUrl=${location.pathname}`
    },
    goToLogin() {
      if (location.pathname.includes('sign-up') || location.pathname.includes('login')) location.href = `/login`
      else location.href = `/login?redirectUrl=${location.pathname}`
    },
  },
})
