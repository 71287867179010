

















































//it's used in airtap-publish-ruler => all paths must be relative
import Vue from 'vue'
import MainInternalMenu from './MainInternalMenu.vue'
import { mapState } from 'vuex'
import { AccountDto } from '../../utils/api'
import LogInDropdown from './LogInDropdown.vue'
import Burger from './Burger.vue'
import Overlay from './Overlay.vue'
export default Vue.extend({
  components: {
    MainInternalMenu,
    LogInDropdown,
    Burger,
    Overlay,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLogInDropdownVisible: false,
    }
  },
  computed: {
    ...mapState({
      isUserLoggedIn: (state: any) => state.auth.isUserLoggedIn,
      user: (state: any) => <AccountDto>state.auth.user,
    }),
    routeName(): string | undefined | null {
      return this.$route.name
    },
    activeDropdownItem(): string {
      if (this.$route.name === 'templates') return 'templates'
      if (this.$route.name === 'pricing') return 'pricing'
      if (this.$route.name === 'feed') return 'feed'
      return ''
    },
    srcLogo() {
      return process.env.VUE_APP_BUCKET_NAME + '/Defaults/logo-uplaunch.svg'
    },
  },
  async created() {
    if (!(<any>process).client) return
    document.addEventListener('click', this.hideLogInDropdown)
    document.addEventListener('keyup', this.hideLogInDropdown)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideLogInDropdown)
    document.removeEventListener('keyup', this.hideLogInDropdown)
  },
  methods: {
    singUp() {
      if (
        location.pathname.includes('login') ||
        location.pathname.includes('sign-up')
      )
        location.href = `/sign-up`
      else location.href = `/sign-up?redirectUrl=${location.pathname}`
    },
    goToLogin() {
      if (
        location.pathname.includes('sign-up') ||
        location.pathname.includes('login')
      )
        location.href = `/login`
      else location.href = `/login?redirectUrl=${location.pathname}`
    },
    toggleMenuDropdown(event: any) {
      if (event) event.preventDefault()

      this.isLogInDropdownVisible = !this.isLogInDropdownVisible
    },
    hideLogInDropdown(event: any) {
      if (event) {
        if (
          event.key === 'Escape' ||
          // These are exceptions: the blocks on what click should NOT close the profile dropdown
          (!event.target.closest('.header__log-in-dropdown') &&
            !event.target.closest('.header__menu-dropdown-toggler')) ||
          // These are blocks on what click SHOULD close the profile dropdown
          event.target.classList.contains('log-in-dropdown__menu-item') ||
          event.target.closest('.log-in-dropdown__menu-item') ||
          event.target.classList.contains(
            'log-in-dropdown__social-menu-item'
          ) ||
          event.target.closest('.log-in-dropdown__social-menu-item') ||
          event.target.closest('.log-in-dropdown__action')
        ) {
          this.isLogInDropdownVisible = false
        }
      }
    },
  },
})
