import Vue from "vue";
import Vuex from "vuex";
import Meta from "vue-meta";
import VueFacebookPixel from "vue-facebook-pixel";
// @ts-ignore
import VueYoutube from "vue-youtube";
import VueGtag from "vue-gtag";
import VueGtm from "@gtm-support/vue2-gtm";
import Hotjar from "vue-hotjar";
import App from "./App.vue";
import { createRouter } from "./router";
import createStore from "./store";
import { sync } from "vuex-router-sync";

import VueCompositionAPI from "@vue/composition-api";
import GAuth from "./utils/social-auth/google-auth";

import MauticPlugin from "./plugins/mautic";

Vue.use(Vuex);
Vue.use(Meta, { ssrAppId: 1 });
Vue.use(VueCompositionAPI);
Vue.use(VueYoutube);

export const createApp = (context: any) => {
  const router = createRouter(
    context.isClient ? context.state.page.info.baseURL : "/"
  );
  const store = createStore(context.state);
  sync(store, router);
  if (context.isClient) {
    Vue.use(MauticPlugin);

    const {
      googleAnalyticsId,
      googleTagManagerId,
      hotjarId,
      facebookPixelId
    } = context.state.page.projectSettings;
    // if (context.   process.client) {
    const gauthOption = {
      clientId:
        "278325185500-n0kcp1er19f8qn136pkrfcae9q6ijd90.apps.googleusercontent.com",
      scope: "profile email https://www.googleapis.com/auth/plus.login"
    };
    Vue.use(GAuth, gauthOption);

    if (googleAnalyticsId) {
      Vue.use(VueGtag, {
        config: { id: googleAnalyticsId }
      });
    }

    if (googleTagManagerId) {
      if (googleTagManagerId.length > 6) {
        console.warn(
          "googleTagManagerId is longer than 6 characters",
          googleTagManagerId
        );
      } else {
        Vue.use(VueGtm, {
          id: googleTagManagerId
        });
      }
    }

    if (hotjarId) {
      Vue.use(Hotjar, {
        id: hotjarId
      });
    }

    if (facebookPixelId) {
      Vue.use(VueFacebookPixel);
      //@ts-ignore
      Vue.analytics.fbq.init(facebookPixelId);
    }
  }

  const app = new Vue({
    store,
    router,
    render: h => h(App)
  });

  return { app, router, store };
};
