































































































































import Vue, { PropType } from 'vue'
import { NotificationDto, NotificationAction } from '../../utils/api'
import Avatar from './avatar/Avatar.vue'
import {
  isYesterday,
  differenceInMonths,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
} from 'date-fns'
const differenceDates = {
  months: differenceInMonths,
  days: differenceInDays,
  hours: differenceInHours,
  min: differenceInMinutes,
}
export default Vue.extend({
  name: 'NotificationsItem',
  components: { Avatar },
  props: {
    data: {
      type: Object as PropType<NotificationDto>,
      required: true,
    },
    isHeader: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isProPlan: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    index: {
      type: Number,
      required: false,
      default: () => 1,
    },
  },
  computed: {
    placeholder(): string {
      return process.env.VUE_APP_BUCKET_NAME + '/Defaults/placeholder.svg'
    },
    isPro(): boolean {
      return !this.isProPlan && this.index % 4 === 0
    },
    proText(): string {
      return this.data.action === NotificationAction.Submission
        ? "You've reached the 3 project limit on your Free plan."
        : `Your submissions limit is reached. You missed ${
            this.data.submission ? this.data.submission.countOverPlan : ''
          } submission.`
    },
  },
  data() {
    return {
      NotificationAction,
    }
  },
  methods: {
    toLastDate(value: any) {
      const year = new Date(value).getFullYear()
      if (!value || year < 1970) return ''

      const utcDate = new Date(value)
      var date = new Date(
        Date.UTC(
          utcDate.getFullYear(),
          utcDate.getMonth(),
          utcDate.getDate(),
          utcDate.getHours(),
          utcDate.getMinutes(),
          utcDate.getSeconds()
        )
      )

      const dateNow = new Date(Date.now())

      if (isYesterday(date)) return 'Yesterday'

      let key: keyof typeof differenceDates
      for (key in differenceDates) {
        const difference = differenceDates[key](dateNow, date)
        if (difference > 0) return `${difference} ${key} ago`
      }

      return ' 1 min ago'
    },
  },
})
